<template>
  <div>
    <button class="small-btn" :class="[
      type,
      {
        'full': isFull
      }
    ]" @click="handleClick">
      <span><slot>Button</slot></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    onClick: {
      type: Function
    },
    isFull: {
      type: Boolean
    }
  },
  methods: {
    handleClick () {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.small-btn {
  outline: none;
  touch-action: manipulation;
  border-radius: 18px;
  /* height: 26px; */
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 1.4rem;
  font-weight: 700;
  border: solid 1px $secondary;
  color: white;
  background-color: $secondary;
  cursor: pointer;
  padding: 4px 16px;
  min-width: 110px;
  &.error {
    color: $error;
    background-color: white;
    border: 1px solid $error;
  }
  &.success {
    color: white;
    background-color: $secondary;
    border: none;
  }
  &.outline {
    color: $secondary;
    background-color: transparent;
    text-align: center;
    border: 1px solid $secondary;
  }
  &.full {
    width: 100%;
  }
  &.revert {
    color: $secondary;
    background: white;
    border: 1px solid white;
  }
}
</style>
