<template>
  <div class="used-badge" :class="{
    'is-not-used': isExpired || (usedTime === limitTime),
    'is-small': isSmall,
    'is-outlined': isOutlined
  }">
    <span v-if="isExpired && usedTime < limitTime" class="has-text-centered is-full-width">{{$t('common.expired')}}</span>
    <span v-else class="has-text-centered is-full-width">{{$t('common.used')}} {{usedTime | formatNumber}} / {{limitTime | formatNumber}}</span>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'UsedBadge',
  props: {
    isSmall: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    usedTime: {
      type: Number,
      default: 0,
      required: true
    },
    limitTime: {
      type: Number,
      default: 0,
      required: true
    },
    expiredDate: {
      type: String
    }
  },
  computed: {
    isExpired () {
      return moment((this.expiredDate) || Date.now()).diff(Date.now(), 'days') <= 0
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.used-badge {
  padding: 4px 20px;
  border-radius: 16px;
  background: $primary;
  color: white;
  min-width: 130px;
  font-size: 1.6rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-items: center;
  &.is-not-used {
    background: $greyb0 !important;
    border: none !important;
    color: white !important;
  }
  &.is-small {
    min-width: 130px;
    font-size: 1.4rem;
    font-weight: bold;
  }
  &.is-outlined {
    border: 1px solid $primary;
    background: white;
    color: $primary;
  }
}
</style>
