<template>
  <div class="coupon-item">
    <div class="is-flex align-items-center">
      <div class="coupon-img-wrapper">
        <img :src="imgPath"
          @error="replaceByDefault"
        />
      </div>
      <div class="has-margin-left-15 flex-1">
        <div class="has-text-black _fs-16 _fw-500">{{name}}</div>
        <div class="has-margin-top-15 is-flex align-items-center justify-space-between">
          <span class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5 has-margin-right-5">{{badge}}</span>
          <div v-if="isHistory">
            <UsedBadge
              is-small
              is-outlined
              :used-time="usedTime"
              :limit-time="limitTime"
              :expired-date="expiredDate"
              @click.native="$emit('onClickMoreDetails')"
             />
          </div>
          <div v-else>
            <SmallButton type="outline" @click.native="$emit('onClickMoreDetails')">{{textButton}}</SmallButton>
          </div>
        </div>
        <div class="has-margin-top-5 _fs-10 has-text-black40" v-if="isShowCouponId">ID : {{couponId}}</div>
        <div class="_fs-10 has-text-black40" v-if="isHistory && lastUsedAt">{{$t('historyPage.lastUsedAt', {lastUsedAt})}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import UsedBadge from '@/components/coupon/UsedBadge.vue'
import SmallButton from '@/components/base/SmallButton.vue'
export default {
  name: 'CouponItem',
  components: {
    SmallButton,
    UsedBadge
  },
  props: {
    name: String,
    couponId: String,
    imgPath: {
      type: String
    },
    id: Number,
    badge: String,
    textButton: String,
    isHistory: Boolean,
    isShowCouponId: {
      type: Boolean,
      default: false
    },
    usedTime: {
      type: Number,
      default: 0
    },
    limitTime: {
      type: Number,
      default: 0
    },
    expiredDate: {
      type: String
    },
    lastUsedDate: {
      type: String
    }
  },
  computed: {
    lastUsedAt () {
      if (!this.lastUsedDate) {
        return null
      }
      return moment(this.lastUsedDate).locale(this.$i18n.locale).format('DD MMM YYYY')
    }
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = require('@/assets/img/coupon-default.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.coupon-item {
  padding: 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .coupon-img-wrapper {
    height: 100px;
    width: 100px;
    background: $greyd0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
